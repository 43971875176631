import Features from "../Features";

const ServicePage = () => {
    return (
        <>
        <Features/>
            <div className="container">
                <div className="row">
                    {/* <div className="col-12 mt-5 p-5 bg-dark">
                    </div> */}
                    <div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ServicePage;