
import React, { useState, useEffect } from 'react';
import './Featuress.css'; 

const Features = () => {
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);

    useEffect(() => {
        // Fetch categories from the API
        fetch('https://api.flukeplay.com/api/psychologist/getcategory')
            .then(response => response.json())
            .then(data => {
                console.log("Fetched categories:", data);
                setCategories(data);
            })
            .catch(error => console.error('Error fetching categories:', error));
    }, []);

    useEffect(() => {
        const fetchSubcategories = async () => {
            const subcategoryPromises = categories.map(category => {
                return fetch(`https://api.flukeplay.com/api/psychologist/getsubcategory?categoryId=${category._id}`)
                    .then(response => response.json());
            });

            try {
                const subcategoryData = await Promise.all(subcategoryPromises);
                console.log("Fetched subcategories for all categories:", subcategoryData);
                const flattenedSubcategories = subcategoryData.flat();
                setSubcategories(flattenedSubcategories);
            } catch (error) {
                console.error('Error fetching subcategories:', error);
            }
        };

        if (categories.length > 0) {
            fetchSubcategories();
        }
    }, [categories]);

    return (
        <div className="container mt-3 mb-3">
            <div className="row">
                {/* Display categories */}
                <div className="col-md-12">
                    <h2>Categories</h2>
                    <div className="row">
                        {categories.map(category => (
                            <div key={category.id} className="col-md-3 mb-3">
                                <div className="card">
                                    <img src={category.categoryImage} className="card-img-top img-fluid" alt=""/>
                                    <div className="card-body">
                                        <h5 className="card-title text-black">{category.categoryName}</h5>
                                        <p className="card-text text-black">{category.categoryDesc}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    {categories.map(category => {
                        const categorySubcategories = subcategories.filter(subcategory => subcategory.categoryId === category._id);
                        if (categorySubcategories.length > 0) {
                            return (
                                <div key={category.id}>
                                    <h2>{category.categoryName}</h2>
                                    <div className="row">
                                        {categorySubcategories.map(subcategory => (
                                            <div key={subcategory.id} className="col-md-3 mb-3">
                                                <div className="card">
                                                    <img src={subcategory.imageUrl} className="card-img-top img-fluid" alt=""/>
                                                    <div className="card-body">
                                                        <h5 className="card-title text-black">{subcategory.subCategoryName}</h5>
                                                        <p className="card-text text-black">{subcategory.categoryDesc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            );
                        }
                        return null; 
                    })}
                </div>
            </div>
        </div>
    );
};

export default Features;

