const AboutUs = () => {

    return(
        <>
        <div className="container">
            <div className="row">

                <div className="col-6 mt-5 bg-primary">

                </div>

                <div className="col-6 mt-5 p-1 bg-info">

                </div>

                <div className="col-lg-8 col-sm-12 mt-1 p-1">About Us
                <h3>Meet the Directors</h3><br/>
                <p>Servu was co-founded by <b>Anees & Umar</b> in March 2024,  with aim to serve the various services at doorstep. Servuhome collaborated with service professionals and launched a platform for users to book services online. It includes a payment gateway for easy booking and transactions. Servu will always rate its professionals for quality and timely delivery of service.  </p>
                    {/* <p><b>Wellness Warriors</b> is a healthcare division of <b>Business Innovation Blaze</b></p> */}
                         <p>Servu is committed to providing you with the best industry professionals at an affordable price. </p></div>

                <div className="col-lg-4 mt-5 p-1">
                    <img src={require(`../img/healthcare-division.png`)} width='100%' alt="about-us" />
                </div>
                <div className="col-12 p-5 "></div>
                

            </div>
            
        </div>
        </>
    )
}

export default AboutUs;