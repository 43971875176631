import { useEffect, useState } from "react";
import Features from "./Features"

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getDownloadURL, ref, getStorage} from 'firebase/storage'
import Psys from "./Psy.css"



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration


const Psy = () => {
    
    const [psychol, setPsychol] = useState([]);

    const firebaseConfig = {
        apiKey: "AIzaSyAhZC88vYXbabXONSme7joJuNgDaPgrqho",
        authDomain: "wellness-warriors-4d0b9.firebaseapp.com",
        projectId: "wellness-warriors-4d0b9",
        storageBucket: "wellness-warriors-4d0b9.appspot.com",
        messagingSenderId: "183707890867",
        appId: "1:183707890867:web:a6f675343aabb0abdeecc6"
      };
      
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      
      const storage = getStorage(app, "gs://my-custom-bucket");
      
      async function getImage (location) {
        
          const imagePath = await getDownloadURL(ref(storage,location))
          console.log(imagePath);
            return imagePath;
          }
            




    useEffect(()=> {
        getPsychol();
    },[]
    )

    const getPsychol = async ()=>{
        let result = await fetch('https://wellnesswarriorsnp.onrender.com/api/v1/admin/getallpsycs');
        result = await result.json();
        setPsychol(result.psycs);
        
    }
    //console.warn("psychologists", psychologists);

    return(
        <>
        <div className="container mt-5 mb-3">
            <div className="row">
                {
                    psychol.filter((item) => item.active_status === true).map((item,index)=>
                    <div className="container col-lg-3 col-sm-6 col-xs-6 mt-2 ">
                    <div className="card">
                    
                        <img src={item.imagepath} className="card-img-top img-fluid"alt=""/>
                            <div className="card-body">
                                <h5 className="card-title text-black center">{item.name}</h5>
                                <p>{item.qualification}</p>
                                
                                <p className="card-text text-black overflow-wrap">{item.about}</p>
                            </div>
                    </div>   
                    </div>
                    )
                }
                
            </div>
        </div>
        </>
    )
}

export default Psy;